import { TProcessOfWork } from './types';

export const ProcessOfWorkItemConfig: TProcessOfWork[] = [
  {
    id: 1,
    title: 'common:meeting',
    text: 'mainPage:meeting.text',
  },
  {
    id: 2,
    title: 'common:scenario',
    text: 'mainPage:scenario.text',
  },
  {
    id: 3,
    title: 'common:shooting',
    text: 'mainPage:shooting.text',
  },
  {
    id: 4,
    title: 'common:installation',
    text: 'mainPage:installation.text',
  },
  {
    id: 5,
    title: 'common:broadcast',
    text: 'mainPage:broadcast.text',
  },
];
