import React, { memo } from 'react';
import { graphql } from 'gatsby';
import { MainPage } from '../templates/MainPage';
import SeoText from 'components/SeoText';
import { Helmet } from "react-helmet"

const SozdanieReklamnojAnimacii = () => {
  return (
    <>
      <Helmet>
        <title>Создание рекламной анимации цена в Москве от Elandic</title>
        <meta name="description" content="Создание рекламной анимации цена в Москве услуги под ключ от студии Elandic. Заказать услуги съемки анимации для бизнеса стоимость от видеопродакшена." />
        <meta name="keywords" content="создание рекламной анимации, съемка, анимационные ролики, для бизнеса, цена" />
      </Helmet>
      <MainPage>
        <SeoText title='Создание рекламной анимации'>
          <p>В современном мире, где внимание аудитории рассеяно на множество каналов, креатив и яркость являются ключевыми факторами успеха в рекламе. Анимационные ролики – это мощный инструмент, позволяющий зацепить внимание и эффективно донести ваше послание до целевой аудитории.</p>

          <p>Elandic – это студия, специализирующаяся на создании высококачественной рекламной анимации в Москве. Мы помогаем бизнесу любого масштаба выделиться из толпы и создать запоминающуюся рекламу, которая будет работать на ваш успех.</p>

          <p>Анимационная реклама обладает рядом преимуществ: она привлекает внимание, удерживает его и способствует лучшему запоминанию информации. Ролики могут быть использованы для различных целей – от демонстрации продукта до рассказа истории бренда. </p>

          <p>Анимация позволяет воплотить любые, даже самые смелые идеи, и создать уникальный стиль для вашего бренда. Анимационные ролики легко адаптировать под разные платформы и форматы, что позволяет расширить охват вашей рекламы. И, что немаловажно, стоимость создания анимационной рекламы в Elandic доступна для бизнеса любого уровня.</p>

          <p>Наши услуги включают в себя разработку концепции, 2D или 3D анимацию, музыкальное сопровождение, постпродакшн. Мы работаем с опытной командой дизайнеров и аниматоров, что гарантирует высокое качество и безупречную реализацию проекта.</p>

          <p>Цена создания рекламной анимации зависит от множества факторов, включая: длительность ролика, сложность анимации, требования к музыкальному сопровождению, дополнительные эффекты и услуги. Мы предоставляем ясный и подробный расчет стоимости услуг еще до начала работы, готовы работать с вашим бюджетом и предложить оптимальное решение для ваших нужд.</p>

          <p>Создайте рекламный ролик, который выделит вас из толпы и позволит вашему бизнесу достичь новых высот. Доверьте создание анимационной рекламы в Москве профессионалам Elandic – мы поможем вам создать эффективную рекламу, которая будет работать на ваш успех.</p>


        </SeoText>
      </MainPage>
    </>
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["mainPage", "common", "layout"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default memo(SozdanieReklamnojAnimacii);
