// extracted by mini-css-extract-plugin
export var about = "MainPageAbout-module--about--ir7Zp";
export var container = "MainPageAbout-module--container--yfVWW";
export var container__caption = "MainPageAbout-module--container__caption--7VOQe";
export var container__caption_heading = "MainPageAbout-module--container__caption_heading--6pwUX";
export var container__caption_quotes = "MainPageAbout-module--container__caption_quotes--326yQ";
export var container__caption_verticalIcon = "MainPageAbout-module--container__caption_verticalIcon--YFwY8";
export var container__description = "MainPageAbout-module--container__description--jF8Tk";
export var container__description__button = "MainPageAbout-module--container__description__button--Qlhca";
export var container__description__button_text = "MainPageAbout-module--container__description__button_text--GaXDH";
export var container__description_text = "MainPageAbout-module--container__description_text--0P7h5";
export var container__heading = "MainPageAbout-module--container__heading--SEPN0";
export var wrapper = "MainPageAbout-module--wrapper--cw6Ts";