// extracted by mini-css-extract-plugin
export var main = "MainPageHeading-module--main--4v7qI";
export var main__container = "MainPageHeading-module--main__container--UcgKI";
export var main__container__button = "MainPageHeading-module--main__container__button--G5B2T";
export var main__container__button__text = "MainPageHeading-module--main__container__button__text--ohfpU";
export var main__container__list__bound = "MainPageHeading-module--main__container__list__bound--j8Y40";
export var main__footer = "MainPageHeading-module--main__footer--Qn0Lr";
export var main__footer__logo = "MainPageHeading-module--main__footer__logo--yHjJp";
export var main__footer__star = "MainPageHeading-module--main__footer__star--pE3Yj";
export var main__video = "MainPageHeading-module--main__video--dsyGi";
export var spin = "MainPageHeading-module--spin--c3okD";