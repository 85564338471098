// extracted by mini-css-extract-plugin
export var container = "MainPageProcessOfWork-module--container--xuJCf";
export var header = "MainPageProcessOfWork-module--header--P+AKI";
export var header__heading = "MainPageProcessOfWork-module--header__heading--QNLW2";
export var header__inner = "MainPageProcessOfWork-module--header__inner--1yr5x";
export var header__quotes = "MainPageProcessOfWork-module--header__quotes--l5W6A";
export var header__title = "MainPageProcessOfWork-module--header__title--TY1NG";
export var header__verticalIcon = "MainPageProcessOfWork-module--header__verticalIcon--d-cQl";
export var main = "MainPageProcessOfWork-module--main--Ms9kl";
export var main__inner = "MainPageProcessOfWork-module--main__inner--7h6Nd";
export var wrapper = "MainPageProcessOfWork-module--wrapper--7+ACD";