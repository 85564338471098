import React, { memo, useCallback, useEffect, useState } from 'react';
import { Carousel, Categories } from 'components';
import { GoToTop } from 'modules';
import { MainPageHeading } from './MainPageHeading';
import { MainPageAbout } from './MainPageAbout';
import { MainPageBrands } from './MainPageBrands';
import { MainPageEnding } from './MainPageEnding';
import { MainPageProcessOfWork } from './MainPageProcessOfWork';
import { Helmet } from 'react-helmet';

function MainPage({ children }: { children?: any }): JSX.Element {
  const [isOpenedCategories, setIsOpenedCategories] = useState<boolean>(false);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const [color, setColor] = useState<string>('#454955');

  const handleToggleCategories = useCallback(() => {
    setIsOpenedCategories((prevState) => !prevState);
  }, []);

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);

    return () => {
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, [isVisible]);

  return (
    <>
      <Helmet>
        <meta name="yandex-verification" content="fe55b5fd07720041" />
      </Helmet>

      <MainPageHeading
        isOpenedCategories={isOpenedCategories}
        onToggleCategories={handleToggleCategories}
      />
      <Categories
        isOpenedCategories={isOpenedCategories}
        onToggleCategories={handleToggleCategories}
      />
      <MainPageAbout />

      {isVisible ? <GoToTop color={color} onClick={handleScrollToTop} /> : null}
      <MainPageBrands />
      <MainPageProcessOfWork />
      <Carousel />
      {children}
      <MainPageEnding />
    </>
  );
}

export default memo(MainPage);
