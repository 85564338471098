import React, { memo } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Icon, Link } from 'modules';
import { separator, arrowBrackets } from 'assets/svg';
import * as styles from './MainPageBrands.module.scss';

function MainPageBrands(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.brands}>
        <div className={styles.media}>
          <div className={styles.media__header}>
            <Icon
              viewBox={arrowBrackets.viewBox}
              path={arrowBrackets.path}
              className={styles.media__icon}
            />
            <p className={styles.media__description}>
              {t('mainPage:brandsCaption')}
            </p>
          </div>
          <video
            className={styles.media__video}
            muted
            autoPlay
            loop
            playsInline
          >
            <source
              src={'/mainPageVideos/mainPageVideo.mp4'}
              type="video/mp4"
            />
          </video>
        </div>
        <div className={styles.content}>
          <video
            className={styles.content__video}
            muted
            autoPlay
            loop
            playsInline
          >
            <source
              src={'/mainPageVideos/mainPageVideo.mp4'}
              type="video/mp4"
            />
          </video>
          <div className={styles.content__links}>
            <div>
              <Icon
                viewBox={arrowBrackets.viewBox}
                path={arrowBrackets.path}
                className={styles.content__links__bigIcon}
              />
            </div>
            <div className={styles.content__links__container}>
              <Link
                className={styles.content__links__link}
                theme="black"
                to="/leaders"
              >
                {t('mainPage:leaders')}
              </Link>
              <Icon
                path={separator.path}
                viewBox={separator.viewBox}
                className={styles.content__links__icon}
              />
              <Link
                className={styles.content__links__link}
                theme="black"
                to="/leaders"
              >
                {t('mainPage:brands')}
              </Link>
              <Icon
                path={separator.path}
                viewBox={separator.viewBox}
                className={styles.content__links__icon}
              />
              <Link
                className={styles.content__links__link}
                theme="black"
                to="/leaders"
              >
                {t('mainPage:history')}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(MainPageBrands);
