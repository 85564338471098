import React, { memo } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { Button, Icon } from 'modules';
import { verticalLine, quotes } from 'assets/svg';
import * as styles from './MainPageAbout.module.scss';

function MainPageAbout(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.about}>
      <div className={styles.container}>
        <div className={styles.wrapper}>
          <div className={styles.container__caption}>
            <Icon
              path={quotes.path}
              viewBox={quotes.viewBox}
              className={styles.container__caption_quotes}
            />
            <h6 className={styles.container__caption_heading}>
              {t('mainPage:heading')}
            </h6>
            <Icon
              path={verticalLine.path}
              viewBox={verticalLine.viewBox}
              className={styles.container__caption_verticalIcon}
            />
          </div>
          <h2 className={styles.container__heading}>{t('mainPage:caption')}</h2>
        </div>
        <div className={styles.container__description}>
          <p className={styles.container__description_text}>
            {t('mainPage:description')}
          </p>
          <Button
            className={styles.container__description__button}
            size="m"
            theme="white"
            hasArrow
            type="button"
            to="/about"
          >
            <span className={styles.container__description__button_text}>
              {t('mainPage:button')}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}

export default memo(MainPageAbout);
