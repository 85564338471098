import React, { memo, useEffect, useState } from 'react';
import classNames from 'classnames';
import * as styles from './SeoText.module.scss';

type Props = {
    title: string;
    children: any;
};

function SeoText({ title, children }: Props): JSX.Element {
    return (
        <div className={classNames(styles.wrapper)}>
            <div className={classNames(styles.container)}>
                <h2 className={classNames(styles.h2)}>{title}</h2>
                <div className={classNames(styles.text)}>
                    {children}
                </div>
            </div>
        </div>
    );
}

export default memo(SeoText);
