import React, { memo } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as styles from './MainPageEnding.module.scss';

function MainPageEnding(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.ending}>
      <div className={styles.ending__emptyContainer} />
      <div className={styles.ending__container}>
        <h3 className={styles.ending__container__heading}>
          {t('mainPage:create')}
        </h3>
      </div>
      <div className={styles.ending__background}>
        <img
          className={styles.ending__background__image}
          src={'/mainPageImg/ending.png'}
          alt={t('layout:footer.image')}
        />
      </div>
    </div>
  );
}

export default memo(MainPageEnding);
