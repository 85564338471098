// extracted by mini-css-extract-plugin
export var brands = "MainPageBrands-module--brands--4ztTM";
export var content = "MainPageBrands-module--content--R3+J-";
export var content__links = "MainPageBrands-module--content__links--99yzT";
export var content__links__bigIcon = "MainPageBrands-module--content__links__bigIcon---0Lr+";
export var content__links__container = "MainPageBrands-module--content__links__container--Uq037";
export var content__links__icon = "MainPageBrands-module--content__links__icon--4bp0O";
export var content__links__link = "MainPageBrands-module--content__links__link--jzLIX";
export var content__video = "MainPageBrands-module--content__video--jlMDZ";
export var media__description = "MainPageBrands-module--media__description--LP3I9";
export var media__header = "MainPageBrands-module--media__header--UJHYQ";
export var media__icon = "MainPageBrands-module--media__icon--pPwFK";
export var media__video = "MainPageBrands-module--media__video--os3mj";
export var wrapper = "MainPageBrands-module--wrapper--SKslf";