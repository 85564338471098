import React, { memo } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ProcessOfWorkItem } from 'components';
import { ProcessOfWorkItemConfig } from 'components/ProcessOfWorkItem/ProcessOfWorkItemConfig';
import { Button, Icon } from 'modules';
import { verticalLine, quotes } from 'assets/svg';
import * as styles from './MainPageProcessOfWork.module.scss';

function MainPageProcessOfWork(): JSX.Element {
  const { t } = useTranslation();

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.header__inner}>
            <Icon
              path={quotes.path}
              viewBox={quotes.viewBox}
              className={styles.header__quotes}
            />
            <h6 className={styles.header__title}>{t('common:how_we_work')}</h6>
            <Icon
              path={verticalLine.path}
              viewBox={verticalLine.viewBox}
              className={styles.header__verticalIcon}
            />
          </div>
          <h1 className={styles.header__heading}>{t('common:stages')}</h1>
        </div>
        <div className={styles.main}>
          <div className={styles.main__inner}>
            {ProcessOfWorkItemConfig.map((item) => (
              <ProcessOfWorkItem
                text={item.text}
                title={item.title}
                id={item.id}
                key={item.id}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(MainPageProcessOfWork);
